<template>
  <div class="offices">
    <b-form-input v-model="filter" type="search" placeholder="Type to Search" :debounce="500"></b-form-input>
    <b-table show-empty striped hover 
      :items="this.fetchData"
      :fields="fields" 
      :current-page="currentPage"
      :per-page="10"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @row-clicked="rowClicked" 
    ></b-table>
    <b-pagination pills
      size="sm"
      v-model="currentPage"
      :total-rows="totalItemsCount"
      :per-page="perPage"
    ></b-pagination>
  </div>
</template>

<script>
import officesService from '../services/offices';

export default {
  name: 'Offices',
  data: () => {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'isVirtualOnly',
          label: 'Virtual Only',
          sortable: true
        },
        {
          key: 'privateRoomsCount',
          label: 'Private Rooms',
          sortable: true
        },
        {
          key: 'city',
          label: 'City',
          sortable: true
        },
        {
          key: 'state',
          label: 'State',
          sortable: true
        }
      ],
      currentPage: 1,
      perPage: 10,
      totalItemsCount: 0,
      filter: null,
      sortBy: null,
      sortDesc: false
    };
  },
  methods: {
    async fetchData(params) {
      if (!params) params = { currentPage: this.currentPage, perPage: this.perPage };
      const list = await officesService.getList(params);
      this.totalItemsCount = list.totalItemsCount;
      return list.items;
    },
    rowClicked(row) {
      this.$router.push(`/offices/${row.id}`);
    }
  }
}
</script>