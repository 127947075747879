import api from './api';
import qs from 'qs';

export default {
    getList: async (params) => {
        const data = qs.stringify(params);
        const response = await api.get(`/offices?${data}`);
        return response.data;
    },
    getById: async (id) => {
        const response = await api.get(`/offices/${id}`);
        return response.data;
    },
    save: async (office) => {
        const response = await api.put(`/offices`, office);
        return response.data;
    }
}